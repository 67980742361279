import React from 'react'

import { PopupModal } from 'react-calendly'
import styled from 'styled-components'

import Filters from 'components/Block/Filters'
import BlockItemDetailView from 'components/Block/ItemDetails'
import BulkIntroRequest from 'containers/ListView/Companies/BulkIntroRequest'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { useCurrentUser, useTeam } from 'store/hooks'
import Widget from 'ui-components/Widget'

import { ComponentWrapper, Title } from './styles'

const StyledEmptyState = styled(EmptyState)`
  margin: unset;
  max-width: 100%;
  border-radius: 0px;
`

interface Props {
  teamSlug: string
  filters: any
  blockItem: any
  setFilters: () => void
  blockId: string
  companyListName: string
  listUuid?: string
}

const Connections: React.FC<Props> = ({
  teamSlug,
  filters,
  blockItem,
  setFilters,
  blockId,
  companyListName,
  listUuid,
}) => {
  const [openCalendly, setOpenCalendly] = React.useState(false)
  const { team } = useTeam(teamSlug)
  const { user } = useCurrentUser()

  const filterType = [
    {
      key: 'search',
      type: 'search',
      icon: 'far fa-search',
      labelStr: 'Search',
      skipLabelInAppliedPill: true,
      noPopover: true,
    },
    {
      key: 'members',
      local_options: 'groups_and_advisors',
      type: 'dropdown[]',
      label: 'Members',
      options: [],
    },
    {
      key: 'groups',
      local_options: 'groups',
      type: 'dropdown[]',
      label: 'Groups',
      options: [],
    },
  ]

  return (
    <Widget title="Connections">
      <div className="flex justify-between items-center">
        <Filters
          teamSlug={teamSlug}
          onChange={setFilters}
          filterTypes={filterType}
          block_id={blockId}
        />
        <BulkIntroRequest teamSlug={teamSlug} filters={filters} />
      </div>

      {team?.enable_asks && (
        <BlockItemDetailView
          filters={filters}
          teamSlug={teamSlug}
          blockItem={blockItem}
          companyListName={companyListName}
          listUuid={listUuid}
        />
      )}

      {!team?.enable_asks && (
        <>
          <StyledEmptyState
            icon={<i className="fal fa-thumbs-up"></i>}
            heading="Activate your contributors with Asks"
            body={<div>Want to see where you have connections?</div>}
            cta={
              <div>
                <CabalButton variant="primary" onClick={() => setOpenCalendly(true)}>
                  Request a free trial
                </CabalButton>
              </div>
            }
          ></StyledEmptyState>
          <PopupModal
            url="https://calendly.com/burke33/30min"
            onModalClose={() => setOpenCalendly(false)}
            open={openCalendly}
            rootElement={document.getElementById('calendly-index')!}
          />
        </>
      )}
    </Widget>
  )
}

export default Connections
