import React from 'react'

import cx from 'classnames'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import flatten from 'lodash/flatten'
import isEqual from 'lodash/isEqual'
import memoizeOne from 'memoize-one'
import { useQuery } from 'react-query'

import { FiltersValueType, renderFilters } from 'components/Filters'
import { getFormattedStage } from 'containers/CompanyPage/SharedListsSection/PortfolioDataTags'
import { COMPANY_SIZE_OPTIONS } from 'containers/ListIndexView/constants'
import Typography from 'global/Typography'
import { useAdvisors, useGroups } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { CompaniesFiltersType } from 'utils/types'

export interface CompaniesFilterProps {
  setFilters: (filters: CompaniesFiltersType) => void
  filters: CompaniesFiltersType
  teamSlug: string
  owningTeamSlug?: string
  disabled?: boolean
  showPortfolioFilters?: boolean
}

const downcastFilters = memoizeOne((filters: FiltersValueType) => {
  const result: CompaniesFiltersType = {}

  const search = filters['search'] as string[] | null
  if (!!search?.length) {
    result.search = search
  }

  const members = filters['members'] as string[] | null
  if (!!members?.length) {
    result.advisor_uuids = members
  }

  const groups = filters['groups'] as string[] | null
  if (!!groups?.length) {
    result.group_uuids = groups
  }

  const sizes = filters['size'] as string[] | null
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters['tags'] as string[] | null
  if (!!tags?.length) {
    result.tags = tags
  }

  const portfolioStages = filters['portfolio_stages'] as string[] | null
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const stages = filters['stages'] as string[] | null
  if (!!stages?.length) {
    result.stage_names = stages
  }

  const owners = filters['owners'] as string[] | null
  if (!!owners?.length) {
    result.owner_emails = owners
  }

  result.request_filter = filters.request_filter

  return result
}, isEqual)

const upcastFilters = memoizeOne((filters: CompaniesFiltersType) => {
  const result: FiltersValueType = {}

  const search = filters.search
  if (!!search?.length) {
    result.search = search
  }

  const members = filters.advisor_uuids
  if (!!members?.length) {
    result.members = members
  }
  const groups = filters.group_uuids
  if (!!groups?.length) {
    result.groups = groups
  }

  const stages = filters.stage_names
  if (!!stages?.length) {
    result.stages = stages
  }

  const portfolioStages = filters.portfolio_stages
  if (!!portfolioStages?.length) {
    result.portfolio_stages = portfolioStages
  }

  const sizes = filters.size
  if (!!sizes?.length) {
    result.size = sizes
  }

  const tags = filters.tags
  if (!!tags?.length) {
    result.tags = tags
  }

  const owners = filters.owner_emails
  if (!!owners?.length) {
    result.owners = owners
  }

  if (filters.request_filter) {
    result.request_filter = filters.request_filter
  }

  return result
}, isEqual)

const CompaniesFilters: React.VFC<CompaniesFilterProps> = ({
  teamSlug,
  filters,
  setFilters,
  owningTeamSlug,
  disabled = false,
  showPortfolioFilters = false,
}) => {
  const { advisors = [] } = useAdvisors({ teamSlug })
  const { groups = [] } = useGroups(teamSlug)

  const { data: ownedCompanyTags } = useQuery(
    ['getInvestorTags', owningTeamSlug],
    () => callApi(api.getInvestorTags, owningTeamSlug!),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!owningTeamSlug,
    },
  )

  const tagOptions = ownedCompanyTags?.tags || []
  const stageOptions = ownedCompanyTags?.stages || []

  const { data: ownerEmailsData } = useQuery(
    ['getAllOwnerEmails', teamSlug],
    () => callApi(api.getAllOwnerEmails, teamSlug),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )
  const ownerEmails = ownerEmailsData?.emails || []

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'search',
        type: 'search',
        icon: 'far fa-search',
        labelStr: 'Search',
        label: 'Search',
        skipLabelInAppliedPill: true,
        noPopover: true,
      },
      !showPortfolioFilters && {
        key: 'members',
        type: 'dropdown[]',
        label: 'Members',
        options: advisors.map((advisor) => ({
          label: advisor.name,
          value: advisor.uuid,
          group: 'Members',
        })),
      },
      !showPortfolioFilters && {
        key: 'groups',
        type: 'dropdown[]',
        label: 'Groups',
        options: groups.map((group) => ({
          label: group.name,
          value: `${group.uuid}`,
          group: 'Groups',
        })),
      },
      !showPortfolioFilters && {
        key: 'stages',
        type: 'dropdown[]',
        label: 'Stage',
        options: stageOptions.map((s: string) => ({ label: getFormattedStage(s), value: s })),
      },
      showPortfolioFilters && {
        key: 'portfolio_stages',
        type: 'dropdown[]',
        label: 'Stage',
        options: stageOptions.map((s: string) => ({ label: getFormattedStage(s), value: s })),
      },
      showPortfolioFilters && {
        key: 'size',
        type: 'dropdown[]',
        label: 'Size',
        options: COMPANY_SIZE_OPTIONS,
      },
      // TODO: Need API to get all tags form portfolio list
      showPortfolioFilters && {
        key: 'tags',
        type: 'dropdown[]',
        label: 'Tags',
        options: tagOptions.map((s: string) => ({ label: s, value: s })),
      },
      {
        key: 'owners',
        type: 'dropdown[]',
        label: 'Owners',
        options: ownerEmails.map((s) => ({ label: s, value: s })),
      },
      !showPortfolioFilters && {
        key: 'request_filter',
        type: 'dropdown',
        label: 'Status',
        options: ['liked', 'drafted', 'sent'].map((s) => ({
          label: s == 'liked' ? 'Offered' : capitalize(s),
          value: s,
        })),
      },
    ].filter(Boolean),
    value: upcastFilters(filters),
    onChange: (f) => setFilters(downcastFilters(f)),
  })

  const clearFilters = () => {
    setFilters({})
  }

  return (
    <div className={cx('flex-col w-full', { 'pointer-events-none opacity-50': disabled })}>
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex flex-wrap gap-2">{renderedFilters}</div>
      </div>
      {appliedFilters.length > 0 && (
        <div className="flex justify-between items-center">
          <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>

          <Typography
            fontSize="12"
            color="fog"
            className="cursor-pointer"
            onClick={() => clearFilters()}
          >
            <i className="far fa-filter-slash mr-1"></i>
            Clear all
          </Typography>
        </div>
      )}
    </div>
  )
}

export default CompaniesFilters
