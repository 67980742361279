import React, { useEffect, useMemo } from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import memoizeOne from 'memoize-one'
import { ArrayParam, BooleanParam, StringParam } from 'use-query-params'

import { FiltersValueType, renderFilters } from 'components/Filters'
import Typography from 'global/Typography'
import { useAdvisors, useAdvisorship, useGroups } from 'store/hooks'

import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import { CompaniesFiltersType } from 'utils/types'

export interface CompaniesFilterProps {
  block_id: string
  teamSlug: string
  disabled?: boolean
  degree?: string
  filterTypes: any
  defaultValue?: CompaniesFiltersType
  onChange?: (filters: CompaniesFiltersType) => void
}

const downcastFilters = memoizeOne((filters: FiltersValueType, filterTypes) => {
  const result: CompaniesFiltersType = {}

  filterTypes.forEach((filter) => {
    // key: 'members' filter is treated differently
    // sent to API as advisor_uuids and group_uuids
    if (filter.local_options == 'groups_and_advisors') {
      const members = filters[filter.key] as string[] | undefined
      result.advisor_uuids = members
    }else if (filter.local_options == 'groups') {
      const members = filters[filter.key] as string[] | undefined
      result.group_uuids = members
    }else{
      const filterValue = filters[filter.key]
      if (Array.isArray(filterValue) && filterValue.length > 0) {
        result[filter.key] = filterValue
      } else if (typeof filterValue == 'string' && filterValue) {
        result[filter.key] = filterValue
      } else if (typeof filterValue == 'boolean') {
        result[filter.key] = filterValue ? 'yes' : 'no'
      }
    }
  })
  return result
}, isEqual)

const upcastFilters = memoizeOne((filters: CompaniesFiltersType, filterTypes) => {
  const result: FiltersValueType = {}

  filterTypes.forEach((filter) => {
    const filterValue = filters[filter.key]
      if (Array.isArray(filterValue) && filterValue.length > 0) {
        result[filter.key] = filterValue
      } else if (typeof filterValue == 'string' && filterValue) {
        result[filter.key] = filterValue
      } else if (typeof filterValue == 'boolean') {
        result[filter.key] = filterValue ? 'yes' : 'no'
      }
  })

  return result
}, isEqual)

const FiltersV2: React.FC<CompaniesFilterProps> = ({
  teamSlug,
  disabled = false,
  degree = '2nd',
  block_id,
  filterTypes,
  defaultValue = {},
  onChange,
}) => {
  const { advisors = [] } = useAdvisors({ teamSlug })
  const { groups = [] } = useGroups(teamSlug)
  const { advisor } = useAdvisorship(teamSlug)

  const queryParamConfigMap = useMemo(() => {
    return {
      advisor_uuids: ArrayParam,
      search: ArrayParam,
      query: StringParam,
      company_list_uuids: ArrayParam,
      group_uuids: ArrayParam,
      stage_names: ArrayParam,
      owner_emails: ArrayParam,
      sources: ArrayParam,
      request_filter: StringParam,
      portfolio: BooleanParam,
      v2: StringParam,
      teams: ArrayParam,
      listType: ArrayParam,
    }
  }, [])

  const [newfilter, setNewFilter] = React.useState({
    advisor_uuids: [],
    group_uuids: [],
  })

  const [filters, setFilters] = useQueryParamsWithSettings(
    queryParamConfigMap,
    'list_filters',
    teamSlug + '_' + block_id,
    defaultValue,
  )

  const options = React.useMemo(
    () => [
      {
        value: advisor?.uuid,
        label: 'My Connections',
        type: 'advisor_uuids',
        lineBelow: true,
        iconUrl: '',
        defaultIcon: '',
      },
    ],
    [advisor?.uuid],
  )

  useEffect(() => {
    onChange?.(filters)
  }, [filters])

  useEffect(() => {
    if (filters['advisor_uuids'] && filters['advisor_uuids']?.length > 0) {
      setNewFilter({
        ...newfilter,
        advisor_uuids: filters['advisor_uuids'],
      })
    }

    if (filters['group_uuids'] && filters['group_uuids']?.length > 0) {
      setNewFilter({
        ...newfilter,
        group_uuids: filters['group_uuids'],
      })
    }
  }, [])

  useEffect(() => {
    const filterOptions = []

    advisors.forEach((advisor) => {
      filterOptions.push({
        value: advisor.uuid,
        label: advisor.name,
        type: 'advisor_uuids',
        iconUrl: advisor.avatar_url,
        defaultIcon: 'fal fa-user',
        lineBelow: false,
      })
    })

    groups.forEach((group) => {
      filterOptions.push({
        value: group.uuid,
        label: group.name,
        type: 'group_uuids',
        iconUrl: '',
        defaultIcon: 'fal fa-users',
        lineBelow: false,
      })
    })

    filterOptions.sort((a, b) => a.label.localeCompare(b.label))

    options.push(...filterOptions)
  }, [advisors, groups, options])

  const defaultFilter = {
    all_connections: true,
  }

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: compact(
      filterTypes.map((filter) => {
        if (filter.local_options == 'groups_and_advisors') {
          filter.options = advisors
            .map((advisor) => ({
              label: advisor.name,
              value: advisor.uuid,
              group: 'Members',
            }))
          return filter
        }else if(filter.local_options == 'groups'){
          filter.options = groups
            .map((group) => ({
              label: group.name,
              value: group.uuid,
              group: 'Groups',
            }))
          return filter
        } else {
          return filter
        }
      }),
    ),
    value: upcastFilters(filters, filterTypes),
    onChange: (f) => setFilters(downcastFilters(f, filterTypes)),
  })


  const clearFilters = () => {
    setFilters({})
  }

  return (
    <div className={cx('flex-col w-full', { 'pointer-events-none opacity-50': disabled })}>
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex flex-wrap gap-2">
          {degree == '2nd' && (
            <>
              {renderedFilters}
            </>
          )}
        </div>
      </div>
      {degree == '2nd' && !!appliedFilters.length && (
        <div className="flex justify-between items-center">
          <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>

          <Typography
            fontSize="12"
            color="fog"
            className="cursor-pointer"
            onClick={() => clearFilters()}
          >
            <i className="far fa-filter-slash mr-1"></i>
            Clear all
          </Typography>
        </div>
      )}
    </div>
  )
}

export default FiltersV2
